<template>
    <div>
        <page-title :title="'系统日志'">查看配置管理平台的操作日志</page-title>
        <div class="main-content">
            <el-tabs v-model="activeTab" type="border-card">
                <el-tab-pane label="操作日志" name="first">
                   <el-table :data="tableData" size="mini" border style="width: 100%">
                        <el-table-column type="index" label="序号" width="50" align="center">
                        </el-table-column>
                        <el-table-column prop="nickName" label="操作用户" width="120" align="center">
                        </el-table-column>
                        <el-table-column prop="name" label="操作日期" width="160" align="center">
                        </el-table-column>
                        <el-table-column prop="userTel" label="操作类型" width="120" align="center">
                        </el-table-column>
                        <el-table-column prop="username" label="操作内容" align="center">
                        </el-table-column>
                        <el-table-column prop="username" label="操作方法" align="center">
                        </el-table-column>
                        <el-table-column prop="roleName" label="操作IP" width="160" align="center">
                        </el-table-column>
                        <el-table-column prop="userDesc" label="操作说明" show-overflow-tooltip header-align="center">
                        </el-table-column>
                    </el-table>
                    <el-pagination
                        small
                        background
                        layout="prev, pager, next"
                        :total="1000"
                        style="margin-top:15px;text-align: right;">
                    </el-pagination>
                </el-tab-pane>
                <el-tab-pane label="登录日志" name="second">
                    系统登录日志
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>
<script>

export default {
    name:"SysLog",
    data(){
        return{
            activeTab:"first",
            tableData:[]
        }
    }
}
</script>
<style lang="less" scoped>
.main-content{
    padding: 0px 20px;
}
</style>